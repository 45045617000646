import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/BlogLayout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import LightBox from '../components/LightBox'
import { connect } from 'react-redux'
import Footer from '../components/Footer2'
export const BlogPostTemplate = ({ title, featuredimage }) => {
    return (
        <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
            </h1>
            <PreviewCompatibleImage
                image={featuredimage}
            />
            <LightBox images={featuredimage} />
        </div>
    )
}
BlogPostTemplate.propTypes = {
    message: PropTypes.string,
    title: PropTypes.string,
    featuredimage: PropTypes.array,
    helmet: PropTypes.object,
}
const BlogPost = ({ data }) => {
    const { markdownRemark: post } = data
    return (
        <Layout>
            <BlogPostTemplate
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
                featuredimage={post.frontmatter.featuredimage}
                frontmatter={post.frontmatter}
            />
        </Layout>
    )
}
BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}
export default connect(
    state => ({
        IsHungarian: state.app.IsHungarian,
    }),
    null
)(BlogPost)
export const pageQuery = graphql`
    query BlogPostByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                tags
                featuredimage{
                    image {
                        childImageSharp {
                          fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                }
                
            }
        }
    }
`
